
import { create } from "zustand";
import axios from "axios";
import { kuTrackAndTraceList } from "../../urls/commonUrl";
import useGeneralStore from "./generalStore";
import useUtilityStore from "./utilityStore";
import { Toastr, calculateDateRange, formatDateToApiDate } from "../../utility/utilityFunctions";
import { t } from "i18next";
import { common_take, user_role as role } from "../../utility/const";
import useDriverStore from "../company/driverStore";

const { setLoading } = useGeneralStore.getState();

const { setLoadingSearch } = useUtilityStore.getState();

const useTrackTraceStore = create((set) => ({

    searchKey: "",
    setSearchKey: (data) => { set({ searchKey: data }); return; },

    apiUrl: kuTrackAndTraceList,
    setApiUrl: (data) => { set({ apiUrl: data }); return; },

    trackTraceList: "",
    setTrackTraceList: (data) => { set({ trackTraceList: data }); return; },

    filterForm: {
        status: "",
        type: "",
        date: "",
        driver_id: "",
        pick_up_date_from: "",
        pick_up_date_to: "",
        drivers: [],
        types: [],
        created_by_type: ""
    },
    setFilterForm: (data) => { set({ filterForm: data }); return; },


    copyFilterForm: {
        status: "",
        type: "",
        date: "",
        driver_id: "",
        pick_up_date_from: "",
        pick_up_date_to: "",
        created_by_type: ""
    },
    setCopyFilterForm: (data) => { set({ copyFilterForm: data }); return; },
    resetCopyFilterForm: () => set({
        copyFilterForm: {
            status: "",
            type: "",
            date: "",
            driver_id: "",
            pick_up_date_from: "",
            pick_up_date_to: "",
            drivers: [],
            types: [],
            created_by_type: ""
        }
    }),

    resetFilterForm: () => set({
        filterForm: {
            status: "",
            type: "",
            date: "",
            driver_id: "",
            pick_up_date_from: "",
            pick_up_date_to: "",
            drivers: [],
            types: [],
            created_by_type: ""
        }
    }),

    showTraceFilterModal: false,
    setShowTraceFilterModal: (data) => { set({ showTraceFilterModal: data }); return; },

    order: null,
    setOrder: (order) => {
        set({ order: order });
        return;
    },

    is_asc: 1,
    setIsAsc: (data) => {
        set({ is_asc: data });
        return;
    },

}));



export const getTrackAndTrace = async (args) => {

    const { is_asc, order, searchKey, apiUrl, setTrackTraceList } = useTrackTraceStore.getState();
    const {user_role} = useGeneralStore.getState();


    let { pick_up_date_from } = args;

    let pick_up_date_to;
    if (args?.pick_up_date_from && args?.pick_up_date_to) {
        pick_up_date_to = args?.pick_up_date_to;
        pick_up_date_from = args?.pick_up_date_from;
    } else if (pick_up_date_from && new Date(pick_up_date_from) < new Date()) {
        pick_up_date_to = new Date().toISOString().split('T')[0];
    } else if (pick_up_date_from && new Date(pick_up_date_from) > new Date()) {
        const dateFrom = new Date(pick_up_date_from);
        dateFrom.setFullYear(dateFrom.getFullYear() + 10);
        pick_up_date_to = dateFrom.toISOString().split('T')[0];
    } else {
        pick_up_date_from = args?.pick_up_date_from ? args?.pick_up_date_from : formatDateToApiDate(new Date("2020-01-01"));
        pick_up_date_to = args?.pick_up_date_to ? args?.pick_up_date_to : args?.pick_up_date_from;
    }


    const body = {
        search: searchKey,
        type: args?.types?.map(item => item?.id),
        date: args?.date,
        driver_id: args?.drivers?.map(item => {return item?.id}),
        pick_up_date_from: pick_up_date_from,
        pick_up_date_to: pick_up_date_to,
        per_page: common_take,
        order_by: order,
        is_asc: is_asc,
        created_by_type: args?.created_by_type,
    }

    if(args?.status) {
        body.status = user_role === role.company ? args?.status : [args.status]
    }

    console.log("track and trace filter", body)

    try {
        if (!args?.isLoading) setLoadingSearch(true); else setLoading(true);
        const res = await axios.get(apiUrl, { params: body });
        console.log('getTrackAndTrace:::', res?.data);
        if (res?.data?.success) {
            setTrackTraceList(res?.data?.data)
        } else {
            Toastr({ "message": res?.data?.message, type: 'error' });
        }
    } catch (error) {
        console.log('getTrackAndTrace:::', error);
        Toastr({ "message": t("An error occurred!"), type: 'error' });
        return false;
    } finally {
        if (!args?.isLoading) setLoadingSearch(false); else setLoading(false);
    }
};


export const resetTrackAndTrace = async (clearSearch = true) => {

    const { setIsAsc, setOrder, setApiUrl, resetCopyFilterForm, setSearchKey, resetFilterForm, setTrackTraceList } = useTrackTraceStore.getState();

    await setTrackTraceList([]);
    await useDriverStore.getState().setDriverList([]);
    if (clearSearch) {
        setSearchKey("")
    }
    resetCopyFilterForm();
    resetFilterForm();
    setApiUrl(kuTrackAndTraceList)
    setOrder(null);
    setIsAsc(1);

}


export const handleTrackAndOrder = async (order_by, action) => {
    const { setIsAsc, is_asc, setOrder, setApiUrl, order } = useTrackTraceStore.getState();
    await setOrder(order_by);
    if (order !== order_by) await setIsAsc(1);
    else await setIsAsc(is_asc ? 0 : 1);
    await setApiUrl(kuTrackAndTraceList);
    const success = await action();
    if (!success) setIsAsc(is_asc ? 0 : 1);
    if (!success && order !== order_by) setIsAsc(1);
}


export default useTrackTraceStore;