/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CommonTable from "../../../../../components/table/CommonTable";
import HistoryFilter from "./components/HistoryFilter";
import useGeneralStore from "../../../../../app/stores/others/generalStore";
import useRequestStore, {
  getTableViewRequestsData,
  handleRequestOrder,
  isTableFiltered,
} from "../../../../../app/stores/others/requestStore";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";
import {
  k_requests_status,
  k_orderBy,
  user_role as role,
  k_request_paths,
  common_take,
} from "../../../../../app/utility/const";
import { kuGetRequestsNew } from "../../../../../app/urls/commonUrl";
import { formatDateTime } from "../../../../../app/utility/utilityFunctions";
import Clamp from "react-multiline-clamp";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

export default function History({ tableTitleClassName }) {
  const {
    history,
    search_key,
    setSearchKey,
    request_take,
    setRequestTake,
    setRequestApiUrl,
    setRequestsOrder,
    setIsAsc,
    request_filter_form,
    resetRequestFilterForm,
    resetFilterRange,
    resetRequestFilterFormCopy,
    is_asc,
    requests_order,
    request_filter_form_copy,
    clearFilterRange,
    hasRequestFilter
  } = useRequestStore();
  const { path_record, user_role } = useGeneralStore();

  const [showModal, setShowModal] = useState(false);
  const [searchValue] = useDebounce(search_key, 500);
  const navigateTo = useNavigate();
  const { t } = useTranslation();

  const headers =
    user_role === role.customer
      ? [
        { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.awarded_company, sort: is_asc, name: t("Company"), onClickAction: () => handleRequestOrder(k_orderBy.awarded_company, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.title, sort: is_asc, name: t("Title"), onClickAction: () => handleRequestOrder(k_orderBy.title, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.completed_at, sort: is_asc, name: t("Completed"), onClickAction: () => handleRequestOrder(k_orderBy.completed_at, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.budget, sort: is_asc, name: t("Budget"), onClickAction: () => handleRequestOrder(k_orderBy.budget, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.status, sort: is_asc, name: t("Status"), onClickAction: () => handleRequestOrder(k_orderBy.status, getData), showSortImage: true },
      ]
      : [
        { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.customer_from, sort: is_asc, name: t("Customer"), onClickAction: () => handleRequestOrder(k_orderBy.customer_from, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.title, sort: is_asc, name: t("Title"), onClickAction: () => handleRequestOrder(k_orderBy.title, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.budget, sort: is_asc, name: t("Price"), onClickAction: () => handleRequestOrder(k_orderBy.budget, getData), showSortImage: true },
        { isActive: requests_order === k_orderBy.completed_at, sort: is_asc, name: t("Completed"), onClickAction: () => handleRequestOrder(k_orderBy.completed_at, getData), showSortImage: true },
      ];

  const getData = async () => {
    const success = await getTableViewRequestsData({
      data_set: k_requests_status.history,
      filter: {
        transport_type: request_filter_form?.transport_types?.map(item => { return item?.id }),
        awarded_company:
          user_role === role.customer
            ? request_filter_form?.awarded_company ? [request_filter_form?.awarded_company] : []
            : [],
        customer_from:
          user_role === role.company
            ? request_filter_form?.customer_from ? [request_filter_form?.customer_from] : []
            : [],
        status: request_filter_form?.status ? [request_filter_form?.status] : [],
        completed_to: request_filter_form?.completed_to,
        completed_from: request_filter_form?.completed_from,
        min_budget: request_filter_form?.min_budget,
        max_budget: request_filter_form?.max_budget,
        created_by_type: request_filter_form?.created_by_type
      },
    });
    if (success) window.scrollTo(0, 0);
  };

  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey("");
    await setRequestApiUrl(kuGetRequestsNew);
    if (request_take !== common_take) await setRequestTake(common_take);
    await setRequestsOrder(k_orderBy.completed_at);
    await setIsAsc(1);
    await resetRequestFilterForm();
    await resetFilterRange();
    await clearFilterRange();
    await resetRequestFilterFormCopy();
    const success = await getTableViewRequestsData({
      data_set: k_requests_status.history,
      filter: {},
      hasFilter: false
    });
    if (success) window.scrollTo(0, 0);
  };

  const handleInitialSetup = async () => {
    if (!hasRequestFilter) {
      resetTable();
    } else {
      getData();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleInitialSetup();
  }, []);

  useEffect(() => {
    getData();
  }, [searchValue]);

  const contents = history?.data?.map((item) => {
    return user_role === role.customer
      ? {
        contents: [
          { value: item?.request_id },
          { value: <Clamp lines={1}> {item?.awarded_company ?? ""}</Clamp> },
          { value: <Clamp lines={1}> {item?.title ?? ""}</Clamp> },
          { value: item?.transport_type },
          { value: <Tooltip title={"Request | Stops | Packages"}>{`1/1 | ${item?.stops_complete_count ?? 0}/${item?.stops_count ?? 0} | ${item?.products_complete_count ?? 0}/${item?.products_count ?? 0}`}</Tooltip> },
          {
            value: formatDateTime(
              item?.completed_at,
              item?.completed_at,
              false,
            )
          },
          {
            value: item?.budget
              ? "DKK " + item?.budget?.toLocaleString("da-DK")
              : ""
          },
          { value: item?.status, className: "capitalize" },
        ],
        clickAction: () =>
          navigateTo(`/requests/history/details/${item?.id}`),
      }
      : {
        contents: [
          { value: item?.request_id },
          {
            value: <Clamp lines={1}>
              {item?.customer_from ?? ""}
            </Clamp>
          },
          { value: <Clamp lines={1}> {item?.title ?? ""}</Clamp> },
          { value: item?.transport_type },
          {
            value: <Tooltip title={"Request | Stops | Packages"}>{`1/1 | ${item?.stops_complete_count ?? 0}/${item?.stops_count ?? 0
              } | ${item?.products_complete_count ?? 0}/${item?.products_count ?? 0
              }`}</Tooltip>
          },
          {
            value: item?.budget
              ? "DKK " + item?.budget?.toLocaleString("da-DK")
              : ""
          },
          {
            value: formatDateTime(
              item?.completed_at,
              item?.completed_at,
              false,
            )
          },
        ],
        clickAction: () =>
          navigateTo(`/requests/history/details/${item?.id}`),
      };
  });

  return (
    <>
      <CommonTable
        tableTitle={
          user_role === role.customer
            ? t("Completed") + `(${history?.total ?? 0})`
            : t("History") + ` (${history?.total ?? 0})`
        }
        showSearchBox={true}
        firstIndexColWidth="flex justify-start !w-max"
        showTopRightFilter={true}
        showTakeOption={true}
        showPagination={true}
        showPageCountText={true}
        headers={headers}
        tableHeaders={headers}
        tableContents={contents}
        widths={
          user_role === role.customer
            ? "90px 20% auto 12% 120px 12% 10% 120px"
            : "90px 20% auto 17% 10% 12% 12%"
        }
        hasClickFunction={true}
        outerPadding="p-s0"
        topRightFilterComponentOnClick={() => setShowModal(true)}
        tableTitleClassName={tableTitleClassName}
        paginationObject={history}
        emptyDataMessage='No completed found!'

        withClearSearch={true}
        onSearchClear={() => {
          setSearchKey("");
        }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setRequestApiUrl(kuGetRequestsNew);
          setSearchKey(e.target.value);
        }}
        currentTakeAmount={request_take}
        withReloader={true}
        onReload={resetTable}
        filtered={isTableFiltered(
          k_requests_status.history,
          request_filter_form_copy,
        )}
        takeOptionOnChange={async (e) => {
          await setRequestTake(e.target.value);
          await setRequestApiUrl(kuGetRequestsNew);
          getData();
        }}
        paginationOnClick={async (url) => {
          await setRequestApiUrl(url);
          getData();
        }}
      />

      <HistoryFilter showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
