/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import CommonTable from '../../../../../components/table/CommonTable'
import OnGoingFilter from './components/OnGoingFilter';
import useGeneralStore from '../../../../../app/stores/others/generalStore';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import useRequestStore, { getTableViewRequestsData, handleRequestOrder, isTableFiltered } from '../../../../../app/stores/others/requestStore';
import { k_requests_status, k_orderBy, user_role as role, k_request_paths, common_take } from '../../../../../app/utility/const';
import { kuGetRequestsNew } from '../../../../../app/urls/commonUrl';
import { formatDateTime } from '../../../../../app/utility/utilityFunctions';
import Clamp from 'react-multiline-clamp';
import { useTranslation } from 'react-i18next';
import useLayoutStore from '../../../../../app/stores/others/layoutStore';
import { Tooltip } from 'antd';

export default function Ongoing({ tableTitleClassName, seeAllText = false }) {

  const { ongoing, search_key, setSearchKey, request_take, setRequestTake, setRequestApiUrl, setRequestsOrder, setIsAsc, request_filter_form, resetRequestFilterForm, resetFilterRange, resetRequestFilterFormCopy, is_asc, requests_order, request_filter_form_copy, hasRequestFilter } = useRequestStore();

  const { path_record, user_role } = useGeneralStore();

  const { setSideBarSelectItemTitle } = useLayoutStore();

  const [showModal, setShowModal] = useState(false);

  const [searchValue] = useDebounce(search_key, 500);

  const navigateTo = useNavigate();

  const { t } = useTranslation();


  const headers = (user_role === role.customer) ? [
    { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.awarded_company, sort: is_asc, name: t("Company"), onClickAction: () => handleRequestOrder(k_orderBy.awarded_company, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.title, sort: is_asc, name: t("Title"), onClickAction: () => handleRequestOrder(k_orderBy.title, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.req_expected_complete_at, sort: is_asc, name: t("Expected Complete"), onClickAction: () => handleRequestOrder(k_orderBy.req_expected_complete_at, getData), showSortImage: true },
  ] : [
    { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.customer_from, sort: is_asc, name: t("Customer"), onClickAction: () => handleRequestOrder(k_orderBy.customer_from, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.driver_name, sort: is_asc, name: t("Driver name"), onClickAction: () => handleRequestOrder(k_orderBy.driver_name, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.req_expected_complete_at, sort: is_asc, name: t("Expected"), onClickAction: () => handleRequestOrder(k_orderBy.req_expected_complete_at, getData), showSortImage: true },
  ];

  const getData = async () => {
    const success = await getTableViewRequestsData({
      data_set: k_requests_status.ongoing, filter: {
        transport_type: request_filter_form?.transport_types?.map(item => item.id),
        exp_date_to: request_filter_form?.exp_date_to,
        exp_date_from: request_filter_form?.exp_date_from,
        awarded_company: user_role === role.customer ? request_filter_form?.awarded_company ? [request_filter_form?.awarded_company] : [] : [],
        shift_driver: user_role === role.company ? request_filter_form?.shift_drivers?.map(item => item?.id) : [],
        created_by_type: request_filter_form?.created_by_type
      }
    });
    if (success) window.scrollTo(0, 0);
  }

  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey('');
    await setRequestApiUrl(kuGetRequestsNew);
    if (window.location.pathname === "/") {
      if (request_take !== common_take) await setRequestTake(common_take);
    } else {
      if (request_take !== common_take) await setRequestTake(common_take);
    }
    await setRequestsOrder(k_orderBy.req_expected_complete_at);
    await setIsAsc(1);
    await resetRequestFilterForm();
    await resetFilterRange();
    await resetRequestFilterFormCopy();
    const success = await getTableViewRequestsData({ data_set: k_requests_status.ongoing, filter: {}, hasFilter: false });
    if (success) window.scrollTo(0, 0);
  }

  const handleInitialSetup = async () => {
    window.scrollTo(0, 0);
    if (window?.location?.pathname === "/" || !hasRequestFilter || !path_record?.old?.includes('/')) {
      resetTable();
    } else {
      getData();
    }
  }

  useEffect(() => { handleInitialSetup() }, []);

  useEffect(() => { handleInitialSetup() }, [window?.location?.pathname === "/"]);

  useEffect(() => { getData() }, [searchValue])

  const contents = ongoing?.data?.map(item => {
    return {
      contents: [
        { value: item?.request_id },
        {
          value: user_role === role.customer ? <Clamp lines={2}> {item?.awarded_company ?? ''}</Clamp>
            :
            <Clamp lines={1}>{item?.customer_from ?? ''}</Clamp>
        },
        { value: user_role === role.customer ? <Clamp lines={2}> {item?.title ?? ''}</Clamp> : <Clamp lines={2}> {item?.driver_name ?? ''}</Clamp> },
        { value: item?.transport_type },
        {
          value:
            //   user_role === role.customer ?
            // <Tooltip title={"Request | Stops | Packages"}>{"1 | " + (item?.stops_count ?? 0) + ' | ' + (item?.products_count ?? 0)}</Tooltip>  
            // :
            <Tooltip title={"Request | Stops | Packages"}>{"0/1 | "}{`${item?.stops_complete_count ?? 0}/${item?.stops_count ?? 0} | ${item?.products_complete_count ?? 0}/${item?.products_count ?? 0}`}</Tooltip>
        },
        { value: formatDateTime(item?.req_expected_complete_at, item?.req_expected_complete_at, false) }
      ],
      clickAction: () => {
        setSideBarSelectItemTitle(`/requests/on-going/details/${item?.id}`)
        navigateTo(`/requests/on-going/details/${item?.id}`);
      }
    }
  })

  return (
    <>
      <CommonTable
        tableTitle={t('Ongoing') + (window?.location?.pathname !== "/" ? ` (${ongoing?.total ?? 0})` : '')}
        showSearchBox={!seeAllText}
        showTopRightFilter={!seeAllText}
        showTakeOption={!seeAllText}
        showPagination={!seeAllText}
        firstIndexColWidth='flex justify-start !w-max'
        showPageCountText={!seeAllText}
        headers={headers}
        outerPadding='p-s0'
        topRightFilterComponentOnClick={() => setShowModal(true)}
        tableTitleClassName={tableTitleClassName}
        paginationObject={ongoing}

        withClearSearch={true}
        onSearchClear={() => { setSearchKey("") }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setRequestApiUrl(kuGetRequestsNew);
          setSearchKey(e.target.value)
        }}

        tableHeaders={headers}
        tableContents={contents}
        hasClickFunction={true}
        widths={user_role === role.company ? "90px auto 25% 18% 160px 13%" : "90px 20% auto 18% 120px 15%"}

        seeAllText={seeAllText && ongoing?.total ? t("See all") : null}
        seeAllLink={"/requests/on-going"}
        seeAllOnClick={() => {
          setSideBarSelectItemTitle("/requests/on-going")
          resetTable();
        }}

        currentTakeAmount={request_take}
        withReloader={true}
        onReload={resetTable}
        filtered={isTableFiltered(k_requests_status.ongoing, request_filter_form_copy)}
        takeOptionOnChange={async (e) => {
          await setRequestTake(e.target.value);
          await setRequestApiUrl(kuGetRequestsNew);
          getData();
        }}
        paginationOnClick={async (url) => {
          await setRequestApiUrl(url);
          getData();
        }}

      />

      <OnGoingFilter showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}
