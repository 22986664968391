/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateRequestStore, {
  clearPickupFrom,
  isCrFormValidationWithAddress,
} from "../../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";
import { create_request_steps, user_role as role } from "../../../../../../../app/utility/const";
import { iBorderedImageUploadIcon } from "../../../../../../../app/utility/imageImports";
import { addMinutesToTime, checkForCurrentDate, getStringFromDateObject, handleInputChange, isCurrentTimeGreaterThan, isFutureDateTime, isPastDate, isPastTime, isWhitespaceOnly, Toastr } from "../../../../../../../app/utility/utilityFunctions";
import RequestAccordion from "../../../../../../../components/Accordion/RequestAccordion";
import ImageUpload from "../../../../../../../components/imageUpload/ImageUpload";
import AddressAutoComplete from "../../../../../../../components/input/AddressAutoComplete";
import CommonDatePicker from "../../../../../../../components/input/CommonDatePicker";
import CommonInput from "../../../../../../../components/input/CommonInput";
import CommonTimePicker from "../../../../../../../components/input/CommonTimePicker";
import CommonSelect from "../../../../../../../components/select/CommonSelect";
import ConfirmChangeDateTimeModal from "./ConfirmChangeDateTimeModal";
import { useLocation } from "react-router-dom";
import useRequestStore from "../../../../../../../app/stores/others/requestStore";

export default function Pickup() {

  const {
    isApplyGenerate,
    cr_form,
    changeCrForm,
    updateCrField,
    type_of_transportation,
    expandedAccordion,
    setExpandedAccordion,
    updateStopField,
    oldPickupDateTime,
    setOldPickupDateTime,
    setCrFullForm,
    setCrFormSelectedShift,
    isFormClearDetected,
    setIsFormClearDetected
  } =
    useCreateRequestStore();

  const { setSelectedShift } = useRequestStore()
  const [doSearch, setDoSearch] = useState(false);
  const [showDateTimeChangeWarning, setShowDateTimeChangeWarning] = useState(false);

  const { t } = useTranslation();
  const { user_role } = useGeneralStore();
  const location = useLocation()

  const [isInvalidAddress, setIsInvalidAddress] = useState(false);
  const [showConfirmPickupDateTimeChange, setShowPickupConfirmDateTimeChange] = useState(false);
  const isEditPage = location?.pathname?.includes("/request/edit")

  const {
    title,
    transport_type,
    pickup: {
      address,
      floor_number,
      start_time,
      end_time,
      duration,
      comment,
      attachment,
      date,
    } = {},
  } = cr_form || {};

  const isPickupFormFilled = [
    title,
    transport_type,
    address,
    floor_number,
    start_time,
    end_time,
    duration,
    comment,
    attachment,
    cr_form?.pickup_attachment_url,
    date,
  ].some(Boolean);

  const handleChange = (e) => {
    updateCrField(e.target.name, e.target.value);
  };

  const isValidAddress = () => {
    if (cr_form?.pickup?.address && cr_form?.pickup?.lat && cr_form?.pickup?.lng) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    setIsInvalidAddress(!isValidAddress())
  }, [cr_form]);

  let curr_hour = (new Date()).getHours();
  let curr_min = (new Date()).getMinutes();

  curr_min = Math.ceil(curr_min / 15) * 15;

  if (curr_min === 60) {
    curr_min = 0;
    curr_hour += 1;
  }

  if (curr_hour === 24) {
    curr_hour = 0;
  }


  function resetDeliveryDateTimes(formData, pickupDate = null, pickupStartTime = "00:00", pickupEndTime = "02:00", withToaster = true) {
    let newFormData = {};
    let newTempDeliveries = [];
    let isResetTriggered = false;

    console.log("pickupDate:", pickupDate);
    console.log("pickupStartTime", pickupStartTime);
    console.log("pickupEndTime", pickupEndTime);



    // Helper function to combine date and time strings into a single Date object
    function combineDateTime(dateStr, timeStr) {
      return new Date(`${dateStr}T${timeStr}:00`);
    }

    formData = cr_form;
    formData.deliveries.forEach(delivery => {
      let resetDelivery = false;

      pickupStartTime = pickupStartTime || "00:00";
      pickupEndTime = pickupEndTime || "02:00";
      if (pickupDate && pickupStartTime && pickupEndTime) {
        // Combine pickup and delivery date-time strings into Date objects
        const deliveryStartDateTime = !!delivery.date ? combineDateTime(delivery.date, delivery.start_time) : null;
        // const pickupEndDateTime = combineDateTime(pickupDate, pickupEndTime);
        const pickupStartDateTime = combineDateTime(pickupDate, pickupStartTime);

        // Check if the delivery times are after the pickup times
        console.log({
          delivery,
          deliveryStartDateTime,
          pickupStartDateTime
        });

        if (!!deliveryStartDateTime && (deliveryStartDateTime <= pickupStartDateTime)) {
          resetDelivery = true;
          isResetTriggered = true;
        }
      }

      newTempDeliveries.push({
        ...delivery,
        date: resetDelivery ? '' : delivery.date,
        start_time: resetDelivery ? '' : delivery.start_time,
        end_time: resetDelivery ? '' : delivery.end_time,
      });
    });

    newFormData = {
      ...formData,
      shift_id: null,
      shift_plan: null,
      deliveries: newTempDeliveries
    };

    console.log({
      isFormClearDetected,
      withToaster,
      isResetTriggered
    });


    // if (isFormClearDetected && withToaster && isResetTriggered) {
    if (withToaster && isResetTriggered) {
      Toastr({ message: "For the change in pickup date/time, Conflicted delivery date and time has been reset.", type: "warning", autoClose: 5000 });
      setIsFormClearDetected(false);
    }

    return newFormData;
  }

  return (
    <div className="overflow-hidden">
      <RequestAccordion
        isInvalid={isApplyGenerate && !isCrFormValidationWithAddress()?.pickup}
        showClearButton={isPickupFormFilled}
        clearButtonTooltipMessage="Clear"
        clearHandler={() => {
          clearPickupFrom(); console.log("clear Pickup HITS !!");
        }}
        title={t("Pickup")}
        subTitleOne={cr_form?.transport_type}
        subTitleTwo={cr_form?.pickup?.address}
        isForm={true}
        isExpanded={expandedAccordion === create_request_steps.pickup}
        onExpand={() => { setExpandedAccordion(expandedAccordion === create_request_steps.pickup ? null : create_request_steps.pickup) }}
      >
        <div className="flex flex-col justify-start">

          <ConfirmChangeDateTimeModal
            showModal={showConfirmPickupDateTimeChange}
            setShowModal={setShowPickupConfirmDateTimeChange}
            onConfirm={() => {
              console.log("CONFIRMED!");
              setShowPickupConfirmDateTimeChange(false);
            }}
            onCancel={() => {
              console.log("CANCELLED!");
              setShowPickupConfirmDateTimeChange(false);
            }}
          />

          <div className="flex flex-row justify-between w-full space-x-5">

            <CommonInput
              max_input={55}
              name={"title"}
              value={cr_form?.title ?? ""}
              onChange={handleChange}
              labelText={t("Title")}
            />

            <div className="w-full mt-[12px]">
              <CommonSelect
                isInvalid={isApplyGenerate && !cr_form?.transport_type}
                required={true}
                label={t("Type")}
                dataArray={type_of_transportation}
                has_subtitle={false}
                onChange={(e, value) => {
                  const targetObject = type_of_transportation?.find((item) => item.value === e.target.value);

                  const { duration } = targetObject ?? 0;
                  const transport_id = targetObject?.id ?? null;

                  if (!value) {
                    changeCrForm("shift_plan", null);
                    changeCrForm("shift_id", null);
                    setCrFormSelectedShift();
                  }

                  updateCrField("transport_type", value);
                  updateCrField("typeoftransportation_id", transport_id);
                  // updateCrField("transport_type", value);
                  if (user_role === role.company) {
                    let validDuration = !!duration ? duration : "";
                    updateCrField("pickup.duration", validDuration);
                  }
                }}
                value={cr_form?.transport_type}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-x-5">

            {/* delivery type and address */}
            <AddressAutoComplete
              isInvalid={cr_form?.pickup?.address && isInvalidAddress}
              className="w-full"
              required={true}
              label={t("Pickup Address")}
              name={"pickup_address"}
              address={cr_form?.pickup?.address ?? ""}
              lat={cr_form?.pickup?.lat}
              lng={cr_form?.pickup?.lng}
              latName={"pickup_lat"}
              lngName={"pickup_lng"}
              changeValue={(name, value) => {

                console.log("address change value", name, value);

                if (!value) {
                  changeCrForm("shift_plan", null);
                  changeCrForm("shift_id", null);
                  setCrFormSelectedShift();
                }

                if (name === "pickup_address") {
                  updateCrField("pickup.address", value);
                } else if (name === "pickup_lat") {
                  updateCrField("pickup.lat", value?.toString());
                } else if (name === "pickup_lng") {
                  updateCrField("pickup.lng", value?.toString());
                }
              }}
              doSearch={doSearch}
              setDoSearch={setDoSearch}
              onBlurEvent={() => { }}
              onFavAddressModalItemClick={(address) => {
                updateCrField("pickup.address", address?.address);
                updateCrField("pickup.lat", address?.lat);
                updateCrField("pickup.lng", address?.lng);
                updateCrField(
                  "pickup.floor_number",
                  address.floor_number === null ? "" : address.floor_number
                );
              }}
            />

            <CommonInput
              type="text"
              labelText={t("Floor number")}
              name={"pickup_floor_number"}
              value={cr_form?.pickup?.floor_number ?? ""}
              onChange={handleInputChange(updateCrField, "pickup.floor_number")}
              max_input={20}
            />
          </div>

          {/* pickup date and time */}
          <div className="grid w-full grid-cols-2 mt-4 gap-x-6">

            <div className={`grid ${user_role === role.company ? "grid-cols-2" : "grid-cols-1"} gap-x-[30px] w-full`}>

              <CommonDatePicker
                isInvalid={isApplyGenerate && !cr_form?.pickup?.date}
                required={true}
                allowPastDate={false}
                label={t("Date")}
                name="pickup_date_formatted"
                value={cr_form?.pickup?.date ?? ""}
                startDate={new Date()}
                shouldUpdateValue={true}

                onChange={(date) => {

                  if (cr_form?.deliveries?.length && user_role === role.company) {
                    let newCrForm = resetDeliveryDateTimes(cr_form, getStringFromDateObject(date), cr_form?.pickup?.start_time ?? "00:00", cr_form?.pickup?.end_time ?? "02:00", true);
                    console.log("newCrForm: ", newCrForm);
                    setCrFullForm(newCrForm);
                  }


                  updateCrField("pickup.date", getStringFromDateObject(date));
                  updateCrField("pickup.start_time", "");
                  updateCrField("pickup.end_time", "");
                  updateStopField("date", "");
                  changeCrForm("shift_plan", null);
                  changeCrForm("shift_id", null);
                  setCrFormSelectedShift(null);
                  updateStopField(
                    "start_time",
                    ""
                  );
                  updateStopField(
                    "end_time",
                    ""
                  );
                  setCrFormSelectedShift()
                  setSelectedShift()
                }}
              />

              {user_role === role.company && <div className="w-full mt-2">
                <CommonTimePicker
                  // isCurrentTimeValidation={formatDate(cr_form?.pickup?.date ?? "") === formatDate(new Date())} //={new Date(cr_form?.pickup_date_formatted)?.toLocaleDateString() === new Date().toLocaleDateString()}
                  isInvalid={isApplyGenerate && !cr_form?.pickup?.start_time}
                  required={true}
                  label={t("Time")}
                  marginClass="mt-s4"
                  disabled={!cr_form?.pickup?.date || (isEditPage && isPastDate(cr_form?.pickup?.date))}

                  init_date={cr_form?.pickup?.date ?? new Date()}
                  isCustomMinTimeValidation={checkForCurrentDate(cr_form?.pickup?.date) ? !isCurrentTimeGreaterThan(cr_form?.pickup?.date, cr_form?.pickup?.start_time) : !isFutureDateTime(cr_form?.pickup?.date) && !isPastDate(cr_form?.pickup?.date) }
                  customMinTimeValue={curr_hour + ":" + curr_min}

                  init_time={cr_form?.pickup?.start_time}

                  showExtendedTimeUi={false}
                  name="pickup_start_time"
                  onChange={(start_time, end_time) => {
                    if (start_time == cr_form?.pickup?.start_time) return;
                    if (cr_form?.deliveries?.length && user_role === role.company) {
                      let newCrForm = resetDeliveryDateTimes(cr_form, cr_form?.pickup?.date, start_time, end_time);
                      console.log("newCrForm: ", newCrForm);
                      setCrFullForm(newCrForm);
                    }

                    updateCrField("pickup.start_time", start_time);
                    updateCrField("pickup.end_time", end_time);
                    setCrFormSelectedShift()
                    setSelectedShift()
                  }}
                />
              </div>}

            </div>

            <div className="grid grid-cols-2 gap-x-[30px]">
              {user_role === role.customer &&
                <div className="w-full mt-2">
                  <CommonTimePicker
                    isInvalid={isApplyGenerate && !cr_form?.pickup?.start_time}
                    required={true}
                    label={t("Time")}
                    marginClass="mt-s4"
                    disabled={!cr_form?.pickup?.date || (isEditPage && isPastDate(cr_form?.pickup?.date))}


                    init_date={cr_form?.pickup?.date ?? new Date()}
                    isCustomMinTimeValidation={checkForCurrentDate(cr_form?.pickup?.date) ? !isCurrentTimeGreaterThan(cr_form?.pickup?.date, cr_form?.pickup?.start_time) : !isFutureDateTime(cr_form?.pickup?.date)}
                    customMinTimeValue={curr_hour + ":" + curr_min}

                    init_time={cr_form?.pickup?.start_time}
                    showExtendedTimeUi={false}
                    name="pickup_start_time"
                    onChange={(start_time, end_time) => {
                      if (start_time == cr_form?.pickup?.start_time) return;
                      if (cr_form?.deliveries?.length && user_role === role.company) {
                        let newCrForm = resetDeliveryDateTimes(cr_form, cr_form?.pickup?.date, start_time, end_time);
                        console.log("newCrForm: ", newCrForm);
                        setCrFullForm(newCrForm);
                      }

                      updateCrField("pickup.start_time", start_time);
                      updateCrField("pickup.end_time", end_time);
                      setCrFormSelectedShift()
                      setSelectedShift()
                    }}
                  />
                </div>
              }

              <div className="w-full mt-2">
                <CommonTimePicker
                  isInvalid={isApplyGenerate && !cr_form?.pickup?.end_time}
                  required={true}
                  label={t("Range")}
                  marginClass="mt-s4"
                  init_time={cr_form?.pickup?.end_time ?? addMinutesToTime(cr_form?.pickup?.start_time, 15)}
                  disabled={!cr_form?.pickup?.start_time || (isEditPage && isPastDate(cr_form?.pickup?.date))}

                  init_date={cr_form?.pickup?.date ?? new Date()}
                  customMinTimeValue={addMinutesToTime(cr_form?.pickup?.start_time, 15)}
                  // isCustomMinTimeValidation={true}

                  showExtendedTimeUi={false}
                  name="pickup_end_time"
                  onChange={(start_time, end_time) => {
                    if(start_time == cr_form?.pickup?.end_time) return;
                    if (cr_form?.deliveries?.length && user_role === role.company) {
                      let newCrForm = resetDeliveryDateTimes(cr_form, cr_form?.pickup?.date, cr_form?.pickup?.start_time, start_time);
                      console.log("newCrForm: ", newCrForm);
                      setCrFullForm(newCrForm);
                    }

                    updateCrField("pickup.end_time", start_time);
                    setCrFormSelectedShift()
                    setSelectedShift()
                  }}
                />
              </div>

              {user_role === role.company && (
                <div className="pb-2 gap-x-5 mt-[-12px]">
                  <CommonInput
                    type="number"
                    labelText={t("Duration (min)")}
                    name={"pickup_duration"}
                    value={cr_form?.pickup?.duration ?? ""}
                    max_input={5}
                    isInvalid={cr_form?.pickup?.duration && cr_form?.pickup?.duration < 3}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      updateCrField("pickup.duration", value);
                    }}
                  />
                </div>
              )}
            </div>

          </div>

          <CommonInput
            type="text"
            textarea={true}
            multiline={true}
            max_input={255}
            value={isWhitespaceOnly(cr_form?.pickup?.comment) ? "" : cr_form?.pickup?.comment}
            name={"pickup_comment"}
            rows={2}
            max={255}
            labelText={t("Comment")}
            onChange={handleInputChange(updateCrField, "pickup.comment")}
          />

          <div className="mt-5">
            <ImageUpload
              setImage={(value) => {
                updateCrField("pickup.attachment", value);
              }}
              image_url={cr_form?.pickup_attachment_url}
              src={cr_form?.pickup?.attachment}
              setSrc={(value) => {
                updateCrField("pickup.attachment", value);
              }}
              title="Add Attachment"
              imageUploadIcon={iBorderedImageUploadIcon}
              addButtonClassName="border border-dashed border-cGray300 p-s10 w-max"
              width="w-max"
              showInfoText={true}
              textStyle="text-fs14 font-fw400 text-cBrand leading-[25px]"
            />
          </div>
        </div>
      </RequestAccordion>
    </div>
  );
}
