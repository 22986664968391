/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";
import useRequestStore, {
  getTableViewRequestsData,
  handleRequestOrder,
  isTableFiltered,
} from "../../../../../app/stores/others/requestStore";
import useGeneralStore from "../../../../../app/stores/others/generalStore";
import {
  k_requests_status,
  k_orderBy,
  k_request_paths,
  user_role as role,
  common_take,
} from "../../../../../app/utility/const";
import { kuGetRequestsNew } from "../../../../../app/urls/commonUrl";
import CommonTable from "../../../../../components/table/CommonTable";
import { formatDateTime } from "../../../../../app/utility/utilityFunctions";
import NotPlannedFilter from "./components/NotPlannedFilter";
import Clamp from "react-multiline-clamp";
import { useTranslation } from "react-i18next";
import useLayoutStore from "../../../../../app/stores/others/layoutStore";
import { Tooltip } from "antd";

export default function NotPlanned({
  tableTitleClassName,
  seeAllText = false,
}) {
  const {
    awarded,
    search_key,
    setSearchKey,
    request_take,
    setRequestTake,
    setRequestApiUrl,
    setRequestsOrder,
    setIsAsc,
    request_filter_form,
    resetRequestFilterForm,
    resetFilterRange,
    clearFilterRange,
    resetRequestFilterFormCopy,
    is_asc,
    requests_order,
    request_filter_form_copy,
    hasRequestFilter
  } = useRequestStore();
  const { path_record, user_role } = useGeneralStore();
  const { setSideBarSelectItemTitle } = useLayoutStore();

  const [showModal, setShowModal] = useState(false);
  const [searchValue] = useDebounce(search_key, 500);
  const navigateTo = useNavigate();
  const { t } = useTranslation();

  const headers = [
    {
      isActive: requests_order === k_orderBy.request_id,
      sort: is_asc,
      index: 1,
      name: t("Req. ID"),
      onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData),
      showSortImage: true,
    },
    user_role === role.customer
      ? { isActive: requests_order === k_orderBy.awarded_company, sort: is_asc, name: t("Company"), onClickAction: () => handleRequestOrder(k_orderBy.awarded_company, getData), showSortImage: true }
      : { isActive: requests_order === k_orderBy.customer_from, sort: is_asc, name: t("Customer"), onClickAction: () => handleRequestOrder(k_orderBy.customer_from, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.awarded_budget, sort: is_asc, name: t("Budget"), onClickAction: () => handleRequestOrder(k_orderBy.awarded_budget, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.pickup_starts_at, sort: is_asc, name: t("Schedule"), onClickAction: () => handleRequestOrder(k_orderBy.pickup_starts_at, getData), showSortImage: true },
  ];

  const getData = () =>
    getTableViewRequestsData({
      data_set: k_requests_status.awarded,
      filter: {
        is_planned: user_role === role.company ? 0 : null,
        transport_type: request_filter_form?.transport_types?.map(item => {return item?.id}),
        pickup_date_from: request_filter_form?.pickup_date_from,
        pickup_date_to: request_filter_form?.pickup_date_to,
        min_budget: request_filter_form?.min_budget,
        max_budget: request_filter_form?.max_budget,
        created_by_type: request_filter_form?.created_by_type
      },
    });

  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey("");
    await setRequestApiUrl(kuGetRequestsNew);
    if (window.location.pathname === "/") {
      if (request_take !== common_take) await setRequestTake(common_take);
    } else {
      if (request_take !== common_take) await setRequestTake(common_take);
    }
    await setRequestsOrder(k_orderBy.pickup_starts_at);
    await setIsAsc(1);
    await resetRequestFilterForm();
    await resetFilterRange();
    await clearFilterRange();
    await resetRequestFilterFormCopy();
    getTableViewRequestsData({
      data_set: k_requests_status.awarded,
      filter: { is_planned: user_role === role.company ? 0 : null },
      hasFilter: false
    });
  };

  const handleInitialSetup = async () => {
    window.scrollTo(0, 0);
    if (
      window?.location?.pathname === "/" ||
      !hasRequestFilter
    ) {
      resetTable();
    } else {
      getData();
    }
  };

  useEffect(() => {
    handleInitialSetup();
  }, []);

  useEffect(() => {
    handleInitialSetup();
  }, [window?.location?.pathname === "/"]);

  useEffect(() => {
    getData();
  }, [searchValue]);

  const contents = awarded?.data?.map((item) => {
    return {
      contents: [
        {value: item?.request_id},
        {value: 
          user_role === role.customer
            ? <Clamp lines={1}> {item?.awarded_company ?? ""} </Clamp>
            : <Clamp lines={1}> {item?.customer_from ?? ""} </Clamp>},
        {value: item?.transport_type},
        {value: <Tooltip title={"Request | Stops | Packages"}>
          {"1 | " + (item?.stops_count ?? 0) + " | " + (item?.products_count ?? 0)}
        </Tooltip>},
        {value: `DKK ${item?.awarded_budget?.toLocaleString("da-DK") ?? ""}`},
        {value: formatDateTime(item?.pickup_starts_at, item?.pickup_starts_time)},
      ],
      clickAction: () => {
        setSideBarSelectItemTitle(
          k_request_paths.not_planned + `/details/${item?.id}`,
        );
        navigateTo(k_request_paths.not_planned + `/details/${item?.id}`);
      },
    };
  });

  return (
    <>
      <CommonTable
        tableTitle={t('Not Planned') + " " + `(${awarded?.total ?? 0})`}
        showSearchBox={!seeAllText}
        showTopRightFilter={!seeAllText}
        firstIndexColWidth="flex justify-start !w-max"
        showTakeOption={!seeAllText}
        showPagination={!seeAllText}
        showPageCountText={!seeAllText}
        tableHeaders={headers}
        tableContents={contents}
        widths="90px auto 20% 120px 13% 13%"
        hasClickFunction={true}
        outerPadding="p-s0"
        topRightFilterComponentOnClick={() => setShowModal(true)}
        tableTitleClassName={tableTitleClassName}
        paginationObject={awarded}
        withClearSearch={true}
        onSearchClear={() => {
          setSearchKey("");
        }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setRequestApiUrl(kuGetRequestsNew);
          setSearchKey(e.target.value);
        }}
        currentTakeAmount={request_take}
        withReloader={true}
        onReload={resetTable}
        filtered={isTableFiltered(
          k_requests_status.awarded,
          request_filter_form_copy,
        )}
        takeOptionOnChange={async (e) => {
          await setRequestTake(e.target.value);
          await setRequestApiUrl(kuGetRequestsNew);
          getData();
        }}
        paginationOnClick={async (url) => {
          await setRequestApiUrl(url);
          getData();
        }}
        seeAllText={awarded?.total > 10 && seeAllText ? t("See all") : ""}
        seeAllLink={k_request_paths.not_planned}
        seeAllOnClick={() => {
          setSideBarSelectItemTitle(k_request_paths.not_planned);
          resetTable();
        }}
        showCommonEmptyData={window?.location?.pathname !== "/"}
      />

      <NotPlannedFilter showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
