/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import CommonModal from '../../../../../../components/modal/CommonModal'
import CommonDatePicker from '../../../../../../components/input/CommonDatePicker'
import CommonSelect from '../../../../../../components/select/CommonSelect'
import FilterRange from '../../../../../../components/utility/FilterRange'
import CommonButton from '../../../../../../components/button/CommonButton'
import useRequestStore, { getTableViewRequestsData, onFilterValueChange, transportationTypeArray } from '../../../../../../app/stores/others/requestStore'
import { k_created_by, k_requests_status, user_role as role } from '../../../../../../app/utility/const'
import { getStringFromDateObject } from '../../../../../../app/utility/utilityFunctions'
import useGeneralStore from '../../../../../../app/stores/others/generalStore'
import { useTranslation } from 'react-i18next'
import { kuGetRequestsNew } from '../../../../../../app/urls/commonUrl'
import MultipleSelectCombo from '../../../../../../components/select/MultipleSelectCombo'
import CommonSingleSelect from '../../../../../../components/select/CommonSingleSelect'

export default function NotPlannedFilter({ showModal, setShowModal }) {
  const { setRequestApiUrl, request_filter_form, changeRequestFilterForm, transport_type, filter_range, filter_range_copy, resetRequestFilterForm, resetFilterRange, resetFilterRangeCopy, updateFilterRangeCopy, updateRequestFilterForm, request_filter_form_copy, updateRequestFilterFormCopy, } = useRequestStore();
  const { user_role } = useGeneralStore();

  const divRef = useRef(null);
  const { t } = useTranslation();


  useEffect(() => { 
    updateRequestFilterForm(request_filter_form_copy)
    if(filter_range_copy?.value) {
      onFilterValueChange(null, filter_range_copy?.value)
    }
   }, [showModal]);

  useEffect(() => { if (divRef.current) divRef.current.focus() }, []);

  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalSpace={true}
      modalTitle={
        <div className='flex items-baseline'>
          <div>{t('Filter')}</div>
          <div className='pl-4 text-base cursor-pointer select-none drop-shadow-sm text-cRed'
            onClick={async () => {
              (user_role === role.customer) ? resetRequestFilterForm() : resetRequestFilterForm(0);
              resetFilterRange();
              resetFilterRangeCopy()
              setRequestApiUrl(kuGetRequestsNew);
              await getTableViewRequestsData({
                data_set: k_requests_status.awarded,
                filter: {
                  is_planned: user_role === role.company ? 0 : null,
                  transport_types: [],
                  pickup_date_from: "",
                  pickup_date_to: "",
                  min_budget: "",
                  max_budget: "",
                  created_by_type: ""
                },
                hasFilter: false
              });
              updateRequestFilterFormCopy("");
              setShowModal(false);
            }} >{t('Clear')}</div>
        </div>
      }
      mainContent={
        <>
          <form className='mt-6' onSubmit={async (e) => {
            e.preventDefault();
            setRequestApiUrl(kuGetRequestsNew);
            await getTableViewRequestsData({
              data_set: k_requests_status.awarded, filter: {
                is_planned: user_role === role.company ? 0 : null,
                transport_type: request_filter_form?.transport_types?.map(item => {return item?.id}),
                pickup_date_from: request_filter_form?.pickup_date_from,
                pickup_date_to: request_filter_form?.pickup_date_to,
                min_budget: request_filter_form?.min_budget,
                max_budget: request_filter_form?.max_budget,
                created_by_type: request_filter_form?.created_by_type,
              }
            });
            updateRequestFilterFormCopy(request_filter_form);
            updateFilterRangeCopy(filter_range)
            setShowModal(false);
          }}>

            <div tabIndex="0" ref={divRef} className='pt-0' ></div>
          <div className="mt-[26px]">

            <MultipleSelectCombo
              label={t('Type')}
              dataArray={transportationTypeArray()}
              onChange={(value) => {
                changeRequestFilterForm('transport_types', value);
              }}
              selectedId={request_filter_form?.transport_types}
              />
            </div>

            {user_role === role.company && <div className="mt-[26px]">
              <CommonSingleSelect
                label={t('Created by')}
                className="capitalize"
                dataArray={
                    [
                        { id: k_created_by.customer, title: "Customer" },
                        { id: k_created_by.company, title: "Company" },
                    ]
                }
                onChange={(value) => {
                  changeRequestFilterForm("created_by_type", value )
                }}
                value={request_filter_form?.created_by_type}
                isCapitalize={true}
              />
            </div>}


            <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
              <CommonDatePicker
                value={request_filter_form?.pickup_date_from}
                onChange={(date) => {
                  if(!date) {
                    changeRequestFilterForm('pickup_date_from', null);
                    return;
                  }
                  if (new Date(date) > new Date(request_filter_form?.pickup_date_to)) {
                    changeRequestFilterForm('pickup_date_from', getStringFromDateObject(date))
                    changeRequestFilterForm('pickup_date_to', "")
                  } else {
                    changeRequestFilterForm('pickup_date_from', getStringFromDateObject(date))
                  }
                }}
                label={t('Pickup From')}
                showClear={true}
              // allowPastDate={false}
              />

              <CommonDatePicker
                startDate={request_filter_form?.pickup_date_from}
                value={request_filter_form?.pickup_date_to}
                onChange={(date) => {
                  if(!date) {
                    changeRequestFilterForm('pickup_date_to', null);
                    return;
                  }
                  changeRequestFilterForm('pickup_date_to', getStringFromDateObject(date))}
                }
                label={t('Pickup To')}
                showClear={true}
              // allowPastDate={false}
              />
            </div>

            <FilterRange
              className={'mt-s26'}
              value={filter_range?.value}
              defaultValue={filter_range?.defaultValue}
              min={filter_range?.min}
              max={filter_range?.max}
              onChange={onFilterValueChange}
            />

            <div className='flex flex-row items-center justify-end w-full mt-5'>
              <CommonButton btnLabel={t('Apply Filter')} type='submit' />
            </div>


          </form>
        </>
      }
    />
  )
}
