/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as React from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import da from 'dayjs/locale/da';
import useSettingsStore from '../../app/stores/others/settingsStore';
import { Tooltip } from 'antd';
import { useState } from 'react';
import { ClickAwayListener, InputAdornment } from '@mui/material';
import { t } from 'i18next';
import { useRef } from 'react';
import { iCalender } from '../../app/utility/imageImports';
import { useEffect } from 'react';
import ImageBtn from '../image/ImageBtn';
import CancelImageBtn from '../image/CancelImageBtn';

const CommonDatePicker = ({
    startDate = null, //(format) = "2023-02-10",
    endDate,
    autoSetLatestValueOnOpen = false,
    allowPastDate = true,
    allowFutureDate = true,
    placeholder = 'Select a date',
    autoFocus = false,
    required = false,
    disabled = false,
    label = "Select Date",
    value = null,
    show_asterisk = true,
    onChange = () => { },
    onError = () => { },
    shouldUpdateValue = false,
    onCloseEvent = () => { },
    isInvalid = false,
    showClear=false,
}) => {
    let startOfYear;
    if (startDate === null) {
        startOfYear = new Date();
        startOfYear.setFullYear(startOfYear.getFullYear() - 10); // Subtract 6 years
        startOfYear.setMonth(0); // January is month 0
        startOfYear.setDate(1);
    } else {
        startOfYear = new Date(startDate);
    }

    const [valueLocal, setValueLocal] = useState();
    const [foundError, setFoundError] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [openCalendar, setOpenCalendar] = useState(false);
    const [safeToCloseCalendar, setSafeToCloseCalendar] = useState(true);
    const [hovered, setHovered] = useState(false);

    const { app_lang_code } = useSettingsStore();
    const datePickerRef = useRef(null);

    React.useEffect(() => {
        switch (foundError) {
            case "minDate":
                setErrorMessage("Invalid date, must be: " + startDate + " or later");
                onError("Invalid date, must be: " + startDate + " or later");
                break;
            case "maxDate":
                setErrorMessage("Invalid date, must be: " + endDate + " or earlier");
                onError("Invalid date, must be: " + endDate + " or earlier");
                break;
            case "disablePast":
                setErrorMessage(t("Invalid date, past date not allowed here."));
                onError("Invalid date, past date not allowed here.");
                break;
            case "disableFuture":
                setErrorMessage("Invalid date, future date not allowed here.");
                onError("Invalid date, future date not allowed here.");
                break;
            case "invalidDate":
                setErrorMessage("Invalid date, please use correct format.");
                onError("Invalid date, please use correct format.");
                break;
            default:
                setErrorMessage("");
                break;
        }

    }, [foundError])

    React.useEffect(() => {
        setValueLocal(value === "" ? null : value);
    }, [value]);

    useEffect(() => {
        if (shouldUpdateValue && openCalendar) {
            if (new Date(value) < new Date() && autoSetLatestValueOnOpen) {
                setValueLocal(new Date());
                onChange(new Date());
            } else {
                setValueLocal(value === "" ? null : value);
            }
        }
    }, [openCalendar]);

    return (

        <div
            className='w-full'>
            <LocalizationProvider dateAdapter={AdapterDayjs}
                adapterLocale={app_lang_code === 'da' ? da : ''}
            >
                <ClickAwayListener onClickAway={(e) => {
                    if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
                        setOpenCalendar(false);
                    }
                }} >
                    <div ref={datePickerRef}>
                        <DatePicker
                            sx={{
                                "& .PrivatePickersYear-yearButton Mui-selected css-3eghsz-PrivatePickersYear-button": {
                                    backgroundColor: '#285D43 !important',
                                    "&:hover": {
                                        // backgroundColor: "#285D43 !important",
                                    },
                                },
                                "& .MuiPickersYear-yearButton": {
                                    backgroundColor: '#ffff !important',
                                    '&:hover': {
                                        backgroundColor: '#CBE9DA !important',
                                    },
                                },
                                "& .MuiTouchRipple-root": {
                                    display: 'none'
                                }
                            }}
                            

                            open={openCalendar} //for manually open/close calendar
                            className='w-full'
                            components={{
                                OpenPickerIcon: () => (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-2px',
                                        right: '12px',
                                        '&:hover': {
                                            backgroundColor: '#ffff', // Assuming the background is what changes on hover
                                        },
                                        display: 'flex',
                                        height: "20px",
                                        alignItems: 'center',
                                        flexDirection: 'row-reverse',
                                        gap: "4px"

                                    }}
                                    >   
                                        <ImageBtn 
                                            imageSrc={iCalender}
                                            hoverImageSrc={iCalender}
                                            hasHoverAction={false}
                                            hoverSize='h-6 w-6'
                                            iconSize='max-w-[20px] min-w-[20px] min-h-[20px] max-h-[20px]'
                                        />
                                        {showClear && hovered && value &&
                                            <CancelImageBtn
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSafeToCloseCalendar(true);
                                                    setOpenCalendar(false);
                                                    setValueLocal(null);
                                                    onChange(null);
                                                }}
                                                />
                                        }
                                    </div>
                                )
                            }}

                            OpenPickerButtonProps={{
                                sx: {
                                    "&:hover": {
                                        backgroundColor: "transparent"
                                    },
                                    "& .MuiTouchRipple-root": {
                                        display: 'none'
                                    },
                                }
                            }}
                            
                            PopperProps={{
                                placement: "bottom-start",
                            }}

                            PaperProps={{
                                sx: {
                                    width: '100%',

                                    "& label": {
                                        color: '#BCC2BF',
                                        fontFamily: 'fEuclidLimadiRegular',
                                        fontWeight: !valueLocal ? '' : 'bold',
                                        textTransform: 'capitalize',
                                    },
                                    '.MuiPickersCalendarHeader-label': {
                                        fontSize: 'initial',
                                    },

                                    "& .PrivatePickersYear-yearButton": {
                                        "&.Mui-selected.Mui-selected": {
                                            backgroundColor: '#285D43', // Set the selected year color
                                            color: '#FFFFFF',
                                            "&:hover": {
                                                backgroundColor: '#285D43', // Set the hover color for selected year
                                                color: '#FFFFFF', // Set the text color for hover on selected year
                                            },
                                        },
                                        "&:hover": {
                                            backgroundColor: '#CBE9DA', // Set the hover color for non-selected years
                                        },
                                    },


                                    "& .MuiPickersDay-root.MuiPickersDay-root": {
                                        "&.Mui-selected.Mui-selected": {
                                            backgroundColor: '#285D43', // Set the hover color
                                            color: '#FFFFFF',
                                            "&:hover": {
                                                backgroundColor: '#285D43', // Set the hover color
                                                color: '#FFFFFF',
                                            },
                                        },
                                        "&.MuiPickersDay-dayDisabled.MuiPickersDay-dayDisabled": {
                                            backgroundColor: '#285D43', // Set the old date background color
                                        },
                                        "&:hover": {
                                            backgroundColor: '#CBE9DA', // Set the hover color
                                        },
                                    },

                                    "&.MuiInput-input": {
                                        fontFamily: "fEuclidLimadiRegular",
                                    },

                                    "& .MuiPickersDay-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: '#285D43', // Set the hover color
                                            color: '#FFFFFF',
                                            "&:hover": {
                                                backgroundColor: '#285D43', // Set the hover color
                                                color: '#FFFFFF',
                                            },
                                        },
                                        "&.MuiPickersDay-dayDisabled": {
                                            backgroundColor: '#285D43', // Set the old date background color
                                        },
                                        "&:hover": {
                                            backgroundColor: '#CBE9DA', // Set the hover color
                                            color: '#242826',
                                        },
                                        cursor: 'pointer',
                                    },
                                    //label color when input is focused
                                    "& label.Mui-focused": {
                                        color: '#BCC2BF',
                                        fontWeight: 'bold',
                                    },

                                    "& .Mui-disabled": {
                                        color: "#939D98",
                                    }

                                }
                            }}
                            label={(required && show_asterisk) ? <div>{label}<span className={`${disabled ? "text-[#939d98]" : "text-[#48504C]"}`}>*</span> </div> : label}
                            value={valueLocal}

                            onMonthChange={() => { setSafeToCloseCalendar(false); }}
                            onYearChange={() => { setSafeToCloseCalendar(false); }}
                            onChange={(newValue) => {
                                setSafeToCloseCalendar(true);
                                setOpenCalendar(false);
                                setValueLocal(newValue);
                                onChange(new Date(newValue));
                            }}
                            inputFormat={app_lang_code === 'da' ? "DD. MMM YYYY" : "DD. MMM YYYY"}
                            disabled={disabled}
                            minDate={startOfYear}
                            maxDate={endDate}
                            disablePast={!allowPastDate}
                            disableFuture={!allowFutureDate}
                            onError={(e) => { setFoundError(e) }}
                            onClose={onCloseEvent}
                            disableMaskedInput={true}
                            
                            

                            renderInput={(params) => (
                                <div className='mt-[3px]' 
                                    onMouseEnter={() => {
                                        if(valueLocal){
                                            setHovered(true);
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        if(valueLocal){
                                            setHovered(false);
                                        }
                                    }}
                                >
                                    <Tooltip color={'#242826'} placement='top' title={errorMessage ? errorMessage : disabled ? "not editable" : ""}>
                                        <TextField {...params}

                                            fullWidth={true}
                                            onKeyDown={(e) => { e.preventDefault(); }}
                                            onClick={() => { if (!disabled) { setOpenCalendar(!openCalendar); } }}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: placeholder,
                                                readOnly: true,
                                                required: required,
                                                autoComplete: 'off',
                                                form: { autoComplete: 'off' }
                                            }}
                                            autoComplete='off'
                                            // disabled={true} 
                                            autoFocus={autoFocus}
                                            focused={autoFocus}
                                            id="standard-basic"
                                            variant="standard"
                                            sx={{
                                                "& label": {
                                                    cursor: 'pointer',
                                                    color: '#48504C',
                                                    fontFamily: 'fEuclidLimadiRegular',
                                                },

                                                '& .MuiFormLabel-asterisk': {
                                                    color: '#48504C',
                                                },

                                                "& .MuiInput-root:before": {
                                                    borderBottom: isInvalid ? "1px solid #F0554B" : '1px solid #949494',
                                                    // borderBottom: isInvalid ? "1px solid #F0554B" : '1px solid rgba(0, 0, 0, 0.42)',
                                                },

                                                "& label.Mui-focused": {
                                                    color: '#BCC2BF',
                                                    fontWeight: 'normal',
                                                    fontFamily: 'fEuclidLimadiRegular',
                                                    marginTop: "4px"
                                                },

                                                // focused color for input with variant='standard'
                                                "& .MuiInput-underline:after": {
                                                    borderBottom: isInvalid ? "1px solid #F0554B" : '1px solid #D7DAD8',
                                                },
                                                // focused color for input with variant='filled'
                                                "& .MuiFilledInput-underline:after": {
                                                    borderBottom: isInvalid ? "1px solid #F0554B" : '1px solid #D7DAD8',
                                                },

                                                "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
                                                    borderBottom: '2px solid #949494',
                                                },
                                                // focused color for input with variant='outlined'
                                                "& .MuiOutlinedInput-root": {
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: isInvalid ? "#F0554B" : '#D7DAD8',
                                                    },
                                                    "&.Mui-disabled": {
                                                        backgroundColor: '#EEF0EE',
                                                        borderColor: '#D7DAD8',
                                                        // height: '15px',
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: '#D7DAD8',
                                                    },
                                                },
                                                // to disable the calender icon and keep only input active
                                                '& .MuiSvgIcon-root': {
                                                    display: 'none'
                                                },
                                                '& .MuiInput-input': {
                                                    color: '#000000',
                                                    cursor: disabled ? "default" : 'pointer',
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                },
                                                '&:hover .MuiInput-input': {
                                                    color: '#000000',
                                                },
                                                "&:hover .MuiInput-underline:before": {
                                                    borderBottomColor: isInvalid ? "#F0554B" : "#949494",
                                                },
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </LocalizationProvider>
        </div >
    )
}

export default CommonDatePicker;
