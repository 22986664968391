import React from 'react'
import useDriverStore from '../../../../app/stores/company/driverStore';
import Image from '../../../../components/image/Image';
import CommonModal from '../../../../components/modal/CommonModal';
import { useTranslation } from 'react-i18next';
import { iChatGreen } from '../../../../app/utility/imageImports';
import { chat_role, user_role as role } from '../../../../app/utility/const';
import useGeneralStore, { getAllUserInfo } from '../../../../app/stores/others/generalStore';
import { commonSelectedChat } from '../../../../app/stores/others/chatStore';
import { useNavigate } from 'react-router-dom';
import CommonDetailsItem from '../../../../components/modal/CommonDetailsItem';

export default function DriverDetailsModal({ showModal, setShowModal }) {

  const { driverDetails } = useDriverStore();

  const { t } = useTranslation();

  const { user_role, isDirty, setIsDirty } = useGeneralStore();

  const navigateTo = useNavigate();

  const handleDirtyCheck = (callBack) => {
    let confirm = false
    if (isDirty && !confirm) {
      confirm = window.confirm('You have unsaved changes. Do you want to leave without saving?')
    }

    if (isDirty && confirm) {
      callBack();
    }

    else if (!isDirty) {
      callBack();
    }
  }

  // console.log(driverDetails)

  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={t("Driver Details")}
      mainContent={
        <>
          {
            <div>
              <div className='flex flex-wrap md:flex-nowrap mt-s16'>

                <Image src={driverDetails?.image} className='max-w-[160px] min-w-[160px] min-h-[160px] max-h-[160px] rounded-full object-cover' />

                <div className="w-full mt-s20 md:mt-0 md:ml-s30">
                  <div>
                    <div className='flex justify-between'>
                      <div className='text-cMainBlack text-fs24 font-fw600 mb-s8'>{t("Profile Info")}</div>
                      <div className='flex justify-center'
                        onClick={() => {
                          handleDirtyCheck(async () => {
                            const res = await getAllUserInfo(driverDetails?.user_id);
                            if (res?.success) {
                              await commonSelectedChat(res?.data, user_role === role.customer ? chat_role.customer : chat_role.company);
                              setShowModal(false);
                              setIsDirty(false);
                              navigateTo("/chat");
                            }
                          })
                        }}>
                        <div className='flex items-center space-x-1 cursor-pointer'>
                          <img
                            src={iChatGreen} alt=''
                            className='cursor-pointer'
                          />
                          <div className='text-fs16 font-fw500 text-cBrand'>Chat</div>
                        </div>
                      </div>
                    </div>

                    <CommonDetailsItem
                      title={t("Name")}
                      value={driverDetails?.name ? driverDetails?.name : 'NA'}
                    />

                    <CommonDetailsItem
                      title={t("Email")}
                      value={driverDetails?.email ? driverDetails?.email : 'NA'}
                    />

                    <CommonDetailsItem
                      title={t("Phone")}
                      value={driverDetails?.phone_from_driver ? driverDetails?.phone_from_driver : 'NA'}
                    />

                    {driverDetails?.comment && <CommonDetailsItem
                      title={t("Instruction")}
                      value={driverDetails?.comment ? driverDetails?.comment : 'NA'}
                    />}
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      }

    />
  )
}
