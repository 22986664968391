/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import CommonModal from '../../../../../../components/modal/CommonModal'
import CommonDatePicker from '../../../../../../components/input/CommonDatePicker'
import CommonSelect from '../../../../../../components/select/CommonSelect'
import CommonButton from '../../../../../../components/button/CommonButton'
import useRequestStore, { getTableViewRequestsData, shiftDriversArray, transportationTypeArray } from '../../../../../../app/stores/others/requestStore'
import { k_created_by, k_requests_status, user_role as role } from '../../../../../../app/utility/const'
import { getStringFromDateObject } from '../../../../../../app/utility/utilityFunctions'
import useGeneralStore from '../../../../../../app/stores/others/generalStore'
import { useTranslation } from 'react-i18next'
import { kuGetRequestsNew } from '../../../../../../app/urls/commonUrl'
import MultipleSelectCombo from '../../../../../../components/select/MultipleSelectCombo'
import CommonSingleSelect from '../../../../../../components/select/CommonSingleSelect'

export default function OnGoingFilter({ showModal, setShowModal }) {
  const { setRequestApiUrl, request_filter_form, changeRequestFilterForm, transport_type, resetRequestFilterForm, resetFilterRange, updateRequestFilterForm, request_filter_form_copy, updateRequestFilterFormCopy, awarded_company, shift_drivers } = useRequestStore();

  const { user_role } = useGeneralStore();

  const divRef = useRef(null);

  const getSelectCompany = () => getTableViewRequestsData({ data_set: k_requests_status.ongoing });

  // useEffect(()=> {
  //   getSelectCompany()
  // }, [])

  useEffect(() => {
    updateRequestFilterForm(request_filter_form_copy);
  }, [showModal]);

  useEffect(() => { if (divRef.current) divRef.current.focus() }, []);
  const { t } = useTranslation();


  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      iAutoFocus={false}
      modalSpace={true}
      modalTitle={
        <div className='flex items-baseline'>
          <div>{t('Filter')}</div>
          <div className='pl-4 text-base cursor-pointer select-none drop-shadow-sm text-cRed'
            onClick={async () => {
              resetRequestFilterForm();
              resetFilterRange();
              setRequestApiUrl(kuGetRequestsNew);
              await getTableViewRequestsData({
                data_set: k_requests_status.ongoing, filter: {
                  transport_types: [],
                  exp_date_to: "",
                  exp_date_from: "",
                  awarded_company: null,
                  shift_driver: null,
                  shift_drivers: [],
                  created_by_type: ""
                },
                hasFilter: false,
              });
              updateRequestFilterFormCopy("");
              setShowModal(false);
            }} >{t('Clear')}</div>
        </div>
      }
      mainContent={
        <>
          <form className='mt-6' onSubmit={async (e) => {
            e.preventDefault();
            setRequestApiUrl(kuGetRequestsNew);
            await getTableViewRequestsData({
              data_set: k_requests_status.ongoing, filter: {
                transport_type: request_filter_form?.transport_types?.map(item => {return item?.id}),
                exp_date_to: request_filter_form?.exp_date_to,
                exp_date_from: request_filter_form?.exp_date_from,
                awarded_company: user_role === role.customer ? request_filter_form?.awarded_company ? [request_filter_form?.awarded_company] : [] : [],
                shift_driver: user_role === role.company ? request_filter_form?.shift_drivers?.map(item => item?.id) : [],
                created_by_type: request_filter_form.created_by_type
              }
            });
            updateRequestFilterFormCopy(request_filter_form);
            setShowModal(false);
          }}>
            <div tabIndex="0" ref={divRef} className='pt-0' ></div>

            <div className="mt-[26px]">
            <MultipleSelectCombo
              label={t('Type')}
              dataArray={transportationTypeArray()}
              onChange={(value) => {
                changeRequestFilterForm('transport_types', value);
              }}
              selectedId={request_filter_form?.transport_types}
            />
            </div>

            <div className='mt-s28'>

              {(user_role === role.customer) ? <CommonSingleSelect 
              label={t('Select Company')}
               placeholder={t('Select Company')} 
               dataArray={awarded_company?.length > 0 ? awarded_company : []} 
               has_subtitle={false} 
               onChange={(value) => {
                changeRequestFilterForm('awarded_company', value);
              }} 
              value={request_filter_form?.awarded_company} 
              showClear={true} /> :
              
                <MultipleSelectCombo label={t('Select Driver')} dataArray={shiftDriversArray()} onChange={(value) => {
                  changeRequestFilterForm('shift_drivers', value);
                }} selectedId={request_filter_form?.shift_drivers} />
              }
            </div>

            {user_role === role.company && <div className="mt-[26px]">
              <CommonSingleSelect
                label={t('Created by')}
                className="capitalize"
                dataArray={
                    [
                        { id: k_created_by.customer, title: "Customer" },
                        { id: k_created_by.company, title: "Company" },
                    ]
                }
                onChange={(value) => {
                  changeRequestFilterForm("created_by_type", value )
                }}
                value={request_filter_form?.created_by_type}
                isCapitalize={true}
              />
            </div>}


            <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
              <CommonDatePicker
                value={request_filter_form?.exp_date_from}
                onChange={(date) => {
                  if (!date) {
                    changeRequestFilterForm('exp_date_from', null)
                    return;
                  }
                  if (new Date(date) > new Date(request_filter_form?.exp_date_to)) {
                    changeRequestFilterForm('exp_date_from', getStringFromDateObject(date))
                    changeRequestFilterForm('exp_date_to', "")
                  } else {
                    changeRequestFilterForm('exp_date_from', getStringFromDateObject(date))
                  }
                }}
                label={t('Expected complete from')}
                showClear={true}
              // allowPastDate={false}
              />

              <CommonDatePicker
                startDate={request_filter_form?.exp_date_from}
                value={request_filter_form?.exp_date_to}
                onChange={(date) => {
                  if (!date) {
                    changeRequestFilterForm('exp_date_to', null)
                    return;
                  }
                  changeRequestFilterForm('exp_date_to', getStringFromDateObject(date))
                }}
                label={t('Expected complete to')}
                showClear={true}
              // allowPastDate={false}
              />
            </div>

            <div className='flex flex-row items-center justify-end w-full mt-5'>
              <CommonButton btnLabel={t('Apply Filter')} type='submit' />
            </div>
          </form>
        </>
      }
    />
  )
}
